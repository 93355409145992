import React, { useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Masonry from 'react-masonry-component';

import bg from '../images/BG-quoideneuf_topright.svg';

import { Layout } from '../components/organisms';
import { PageWrapper, RenderMarkdown } from '../components/atoms';
import { colors, dimensions } from '../styles/variables';
import {
  ActualiteCard,
  ActualiteLinkProps,
  ActualityCategoryProps,
  ArticleProps,
  Footer,
} from '../components/molecules';

const Content = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 72px;
  padding-bottom: ${dimensions.space.fourtyEight};
  flex-grow: 1;
`;

const Title = styled.h1`
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  text-align: center;
  margin-top: ${dimensions.space.fourtyEight};
  margin-bottom: ${dimensions.space.twentySeven};
`;

const Description = styled.div`
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const Categories = styled.div`
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
  column-gap: 10px;
`;

const CategoriesFilterText = styled.div`
  font-weight: bold;
`;

const CategoryButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const CategoryButtonIcon = styled(Img)`
  position: absolute !important;
  width: 35px !important;
  height: 35px !important;
`;

const CategoryButtonText = styled.span`
  padding: 0 5px;
`;

const CategoryButton = styled.div<{ withIcon?: boolean; selected: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.lila};
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  color: ${colors.purple};

  &:hover {
    color: ${colors.gold};
    box-shadow: 0px 0px 4px #3915574d;
  }

  ${(props) =>
    props.selected &&
    `
    color: ${colors.gold};
    box-shadow: 0px 0px 4px #3915574d;
  `}

  ${CategoryButtonText} {
    padding-left: ${(props) => props.withIcon && '40px'};
  }
`;

const Actualites = styled(Masonry)`
  margin: 55px auto 0;
  position: relative;
  z-index: 0;
  max-width: 100%;
`;

type Props = {
  data: {
    strapiActualityPage: {
      title: string;
      description: string;
    };
    allStrapiActuality: {
      edges: [
        {
          node: {
            id: string;
            title: string;
            content: string;
            type: string;
            media: string;
            links: ActualiteLinkProps[];
            headerImage: {
              localFile: {
                childImageSharp: {
                  fluid: FluidObject;
                };
              };
            };
            actuality_categories: { id: number }[];
          };
        },
      ];
    };
    allStrapiArticle: {
      edges: [
        {
          node: ArticleProps;
        },
      ];
    };
    allStrapiActualityCategory: {
      edges: [
        {
          node: ActualityCategoryProps;
        },
      ];
    };
  };
};

export default function ActualitesPage({ data }: Props) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = data.allStrapiActualityCategory.edges.map((o) => o.node);
  const articles = data.allStrapiArticle.edges.map((o) => o.node);

  return (
    <Layout title={data.strapiActualityPage.title} description="">
      <Content>
        <PageWrapper>
          <Title>{data.strapiActualityPage.title}</Title>
          <Description>
            <RenderMarkdown>{data.strapiActualityPage.description}</RenderMarkdown>
          </Description>
          <Categories>
            <CategoriesFilterText>Filtrer les publications :</CategoriesFilterText>
            <CategoryButtons>
              <CategoryButton
                selected={selectedCategory === null}
                onClick={() => setSelectedCategory(null)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => (e.key === 'Enter' ? setSelectedCategory(null) : {})}
              >
                <CategoryButtonText>Tout afficher</CategoryButtonText>
              </CategoryButton>
              {categories.map((o) => (
                <CategoryButton
                  key={o.id}
                  withIcon
                  selected={selectedCategory === o.strapiId}
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedCategory(o.strapiId)}
                  onKeyDown={(e) => (e.key === 'Enter' ? setSelectedCategory(o.strapiId) : {})}
                >
                  <CategoryButtonIcon
                    imgStyle={{ objectFit: 'contain' }}
                    fixed={o.icon.localFile.childImageSharp.fixed}
                  />
                  <CategoryButtonText>{o.name}</CategoryButtonText>
                </CategoryButton>
              ))}
            </CategoryButtons>
          </Categories>
          <Actualites enableResizableChildren options={{ fitWidth: true }}>
            {data.allStrapiActuality.edges.map((o) => (
              <ActualiteCard
                {...o.node}
                key={o.node.id}
                articles={articles}
                categories={categories}
                selectedCategory={selectedCategory}
              />
            ))}
          </Actualites>
        </PageWrapper>
      </Content>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiActualityPage {
      title
      description
    }

    allStrapiActuality(sort: { fields: releaseDate }) {
      edges {
        node {
          id
          title
          content
          type
          media
          links
          headerImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          actuality_categories {
            id
          }
        }
      }
    }

    allStrapiArticle {
      edges {
        node {
          strapiId
          id
          title
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          gallery {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allStrapiActualityCategory(sort: { fields: order }) {
      edges {
        node {
          id
          name
          strapiId
          icon {
            localFile {
              childImageSharp {
                fixed(width: 40) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
